import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// services
import Gupport from "../../services/gupport";
// types
import type { WithTranslation } from "react-i18next";
import type { UserId } from "../../types/user";
import type { CmdGetUserLogs } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	userId: UserId;
}>;

type State = {
	loading: boolean;
	error: any;
	logs: any;
};

class UserLogs extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			logs: [],
		};

		this.refresh = this.refresh.bind(this);
	}

	override componentDidMount() {
		this.fetchLogs(this.props.userId);
	}

	refresh() {
		this.fetchLogs(this.props.userId);
	}

	fetchLogs(userId) {
		const { t } = this.props;
		if (Gupport.getUserLogs) {
			const cmd = {
				action: "getUserLogs",
				username: userId,
			} as const satisfies CmdGetUserLogs;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						loading: false,
						error: null,
						logs: msg.payload.data,
					});
				} else {
					this.setState({
						loading: false,
						error: msg.payload.data,
						logs: [],
					});
				}
			});
		} else {
			this.setState({
				loading: false,
				error: t("users.lError"),
				logs: [],
			});
		}
	}

	override render() {
		const { t } = this.props;
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const columns = [
			{
				field: "logText",
				headerName: t("users.lText"),
				flex: 6,
			},
			{
				field: "_timestamp",
				headerName: t("users.lTimestamp"),
				flex: 1,
				renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>),
			},
			{
				field: "level",
				headerName: t("users.lLevel"),
				flex: 1,
			},
		];

		return (
			<Paper>
				<DataGrid
					loading={this.state.loading}
					columns={columns}
					rows={this.state.logs}
				/>
			</Paper>
		);
	}

}

export default withTranslation()(UserLogs);
