import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// cmp
import Na from "../na";
// services
import Gupport from "../../services/gupport";
import { formatBytes } from "../../services/utils";
import { muiTheme } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { UserId } from "../../types/user";
import type { CmdGetGlients } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	userId: UserId;
}>;

type State = {
	loading: boolean;
	error: any;
	sockets: any;
};

class UserSockets extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			sockets: [],
		};

		this.refresh = this.refresh.bind(this);
	}

	override componentDidMount() {
		this.fetchSockets(this.props.userId);
	}

	refresh() {
		this.fetchSockets(this.props.userId);
	}

	fetchSockets(userId) {
		const { t } = this.props;
		if (Gupport.getGlients) {
			const cmd = {
				action: "getGlients",
				username: userId,
			} as const satisfies CmdGetGlients;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						loading: false,
						error: null,
						sockets: msg.payload.data,
					});
				} else {
					this.setState({
						loading: false,
						error: msg?.payload.data,
						sockets: [],
					});
				}
			});
		} else {
			this.setState({
				loading: false,
				error: t("users.lError"),
				sockets: [],
			});
		}
	}

	override render() {
		const { t } = this.props;
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const columns = [
			{
				field: "gagents",
				headerName: t("users.sGateway"),
				flex: 3,
				renderCell: (params) => (
					params.value.map((gagent, index) => (
						<Fragment key={gagent}>
							{(index > 0) && ", "}
							<a
								href={`gateway_desc.html#/gateways/${encodeURIComponent(gagent)}/general`}
								target="_blank"
								rel="noopener noreferrer"
								style={{color: muiTheme.palette.primary.main, textDecoration: "none"}}
							>
								{gagent}
							</a>
						</Fragment>
					))
				),
				valueFormatter: (value) => (value.join(", ")),
			},
			{
				field: "connected",
				headerName: t("users.sconnected"),
				flex: 2,
				renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>),
			},
			{
				field: "agent",
				headerName: t("users.sAgent"),
				flex: 3,
				renderCell: (params) => (params.value ?? <Na />),
			},
			{
				field: "txCount",
				headerName: t("users.txCount"),
				flex: 1,
			},
			{
				field: "txSize",
				headerName: t("users.txSize"),
				flex: 1,
				renderCell: (params) => (formatBytes(params.value) || <Na />),
			},
			{
				field: "rxCount",
				headerName: t("users.rxCount"),
				flex: 1,
			},
			{
				field: "rxSize",
				headerName: t("users.rxSize"),
				flex: 1,
				renderCell: (params) => (formatBytes(params.value) || <Na />),
			},
		];

		return (
			<Paper>
				<DataGrid
					loading={this.state.loading}
					columns={columns}
					rows={this.state.sockets}
				/>
			</Paper>
		);
	}

}

export default withTranslation()(UserSockets);
