import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Paper,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
} from "@mui/material";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
// types
import type { WithTranslation } from "react-i18next";
import type { UserId } from "../../types/user";
import type { CmdDeleteB2CToken, CmdGetB2CTokens, UserB2CToken } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	userId: UserId;
}>;

type State = {
	loading: boolean;
	inProgress: boolean;
	error: Error | null;
	selectedToken: UserB2CToken | null;
	tokens: ReadonlyArray<UserB2CToken>;
	openDeleteTokenDialog: string | undefined;
};

const DIALOG_TYPES = {
	ALL: "all",
	ONE: "one",
} as const;

class B2CTokens extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			inProgress: false,
			error: null,
			selectedToken: null,
			tokens: [],
			openDeleteTokenDialog: undefined,
		};

		this.refresh = this.refresh.bind(this);
		this.fetchTokens = this.fetchTokens.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	override componentDidMount() {
		this.fetchTokens(this.props.userId);
	}

	refresh() {
		this.fetchTokens(this.props.userId);
	}

	fetchTokens(userId) {
		this.setState({
			loading: true,
		});

		const cmd = {
			action: "getB2CTokens",
			username: userId,
		} as const satisfies CmdGetB2CTokens;
		Gupport.send(cmd, (_error, msg) => {
			if (!_error && msg?.payload.status === "ok") {
				this.setState({
					loading: false,
					error: null,
					tokens: msg.payload.data.tokens,
				});
			} else {
				this.setState({
					loading: false,
					error: _error,
					tokens: [],
				});
			}
		});
	}

	showDeleteDialog(type, token) {
		this.setState({
			openDeleteTokenDialog: type,
			selectedToken: token,
		});
	}

	handleClose() {
		this.setState({
			openDeleteTokenDialog: undefined,
			selectedToken: null,
		});
	}

	deleteToken(deleteAll: boolean = false) {
		this.setState({
			inProgress: true,
		});

		const cmd = {
			action: "deleteB2CToken",
			data: {
				tokens: deleteAll ? this.state.tokens : [this.state.selectedToken!], // eslint-disable-line @typescript-eslint/no-non-null-assertion
			},
			username: this.props.userId,
		} as const satisfies CmdDeleteB2CToken;
		Gupport.send(cmd, (_error, msg) => {
			if (!_error && msg?.payload.status === "ok") {
				this.fetchTokens(this.props.userId);
			}
			this.setState({
				inProgress: false,
				selectedToken: null,
				openDeleteTokenDialog: undefined,
			});
		});
	}

	override render() {
		const { t } = this.props;
		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.error) {
			return <div>{this.state.error.message}</div>;
		}

		const hasWriteAccess = User.hasLevel("user_b2c_write");

		return (
			<>
				{hasWriteAccess &&
					<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "8px 8px 8px 0" }}>
						<Button variant="contained" color="danger" onClick={this.showDeleteDialog.bind(this, DIALOG_TYPES.ALL)}>{t("users.b2cTokens.deleteAll")}</Button>
					</div>
				}
				<Paper sx={{ overflow: "hidden" }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t("users.b2cTokens.token")}</TableCell>
								<TableCell>{t("users.b2cTokens.endtime")}</TableCell>
								<TableCell>{t("users.b2cTokens.expiry")}</TableCell>
								<TableCell>{t("users.b2cTokens.lastRefresh")}</TableCell>
								<TableCell>{t("users.b2cTokens.policy")}</TableCell>
								{hasWriteAccess && <TableCell>{t("users.b2cTokens.actions")}</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{(this.state.tokens.length === 0) ?
								<TableRow className="last-row-no-border">
									<TableCell>{t("users.b2cTokens.noTokens")}</TableCell>
								</TableRow>
								:
								this.state.tokens.map((token) => (
									<TableRow key={token.id} className="last-row-no-border">
										<TableCell>{token.id}</TableCell>
										<TableCell><time dateTime={new Date(token.logon_ts * 1000).toISOString()}>{new Date(token.logon_ts * 1000).toLocaleString()}</time></TableCell>
										<TableCell><time dateTime={new Date(token.refresh_token_exp * 1000).toISOString()}>{new Date(token.refresh_token_exp * 1000).toLocaleString()}</time></TableCell>
										<TableCell><time dateTime={new Date(token.created).toISOString()}>{new Date(token.created).toLocaleString()}</time></TableCell>
										<TableCell>{token.policy}</TableCell>
										{hasWriteAccess &&
											<TableCell>
												<Button variant="contained" color="danger" onClick={this.showDeleteDialog.bind(this, DIALOG_TYPES.ONE, token)}>
													{t("users.delete")}
												</Button>
											</TableCell>
										}
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</Paper>
				{hasWriteAccess &&
					<Dialog
						fullWidth={true}
						open={Boolean(this.state.openDeleteTokenDialog)}
						onClose={this.handleClose}
					>
						<DialogTitle>{t(this.state.openDeleteTokenDialog === DIALOG_TYPES.ONE ? "users.b2cTokens.dialogDelete" : "users.b2cTokens.dialogDeleteAll")}</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{t(this.state.openDeleteTokenDialog === DIALOG_TYPES.ONE ? "users.b2cTokens.deleteConfirm" : "users.b2cTokens.deleteAllConfirm")}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								color="danger"
								loading={this.state.inProgress}
								onClick={this.deleteToken.bind(this, this.state.openDeleteTokenDialog === DIALOG_TYPES.ALL)}
							>
								{t(this.state.openDeleteTokenDialog === DIALOG_TYPES.ONE ? "dialog.delete" : "users.b2cTokens.deleteAll")}
							</Button>
							<Button color="inherit" onClick={this.handleClose}>{t("dialog.cancel")}</Button>
						</DialogActions>
					</Dialog>
				}
			</>
		);
	}

}

export default withTranslation()(B2CTokens);
