import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import {
	CircularProgress,
	Paper,
	List,
	ListItemButton,
	ListItemText,
	Divider,
} from "@mui/material";
import { red } from "@mui/material/colors";
// cmp
import Terminal from "../terminal/terminal";
// services
import Constants from "../../services/constants";
import Gupport from "../../services/gupport";
import CC from "../../services/cc";
import { TerminalType, SessionIdGeneral } from "../../services/terminal";
// types
import type { WithTranslation } from "react-i18next";
import type { GupportMessageRxKnown, PayloadBroadcastSubTrace } from "../../types/gupport";
import type { CcMessageRxKnown, CcPayloadRxKnown } from "../../types/cc";
import type { UserId } from "../../types/user";
import type { Direction, SessionId, SessionIdOrGeneral, SessionTraces, GeneralTrace, SessionTrace, Log } from "../../types/terminal";
import type { CmdTraceGlient } from "../../types/gupport";

const GENERAL_TRACE = {
	sessionId: SessionIdGeneral,
	logs: [],
} as const satisfies GeneralTrace;

type Props = Readonly<WithTranslation & {
	userId: UserId;
}>;

type State = {
	// ready: boolean;
	loading: boolean;
	error: string | undefined;
	generalTrace: GeneralTrace;
	traces: SessionTraces;
	selectedTraceSessionId: SessionIdOrGeneral;
};

class UserTrace extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			error: undefined,
			generalTrace: GENERAL_TRACE,
			traces: [],
			selectedTraceSessionId: SessionIdGeneral,
		};

		this.handleGupportMessage = this.handleGupportMessage.bind(this);
		this.handleLoadFile = this.handleLoadFile.bind(this);
	}

	override componentDidMount() {
		Gupport.on("message-rx", this.handleGupportMessage);

		this.traceGlient(this.props.userId);
	}

	override componentWillUnmount() {
		Gupport.off("message-rx", this.handleGupportMessage);

		this.disconnectAll();
	}

	async handleGupportMessage(msg: GupportMessageRxKnown) {
		if (msg.payload.info === "subTrace") {
			switch (msg.payload.type) {
				case "createSession": {
					await this.createSession(msg.payload);
					break;
				}
				case "closeSession":
					this.closeSession(msg.payload);
					break;
				default:
			}

			const log = {
				type: "rtrace",
				data: msg.payload.msg,
				ts: msg.payload.ts ?? new Date().toISOString(),
				dir: UserTrace.getDir(msg.payload),
			} as const satisfies Log;
			this.storeTraceToSession(SessionIdGeneral, log);
		}
	}

	storeTraceToSession(sessionId: SessionIdOrGeneral, log: Log) {
		if (sessionId === SessionIdGeneral) {
			this.setState((prevState) => ({
				generalTrace: {
					...prevState.generalTrace,
					logs: [...prevState.generalTrace.logs, log],
				},
			}));
		} else {
			this.setState((prevState) => ({
				traces: prevState.traces.map((trace) => (
					(trace.sessionId === sessionId) ? { ...trace, logs: [...trace.logs, log] } : trace
				)),
			}));
		}
	}

	handleLoadFile(file: File) {
		const fileReader = new FileReader();
		fileReader.readAsText(file, "UTF-8");
		// TODO: replace onload
		fileReader.onload = (event) => { // eslint-disable-line unicorn/prefer-add-event-listener
			try {
				const trace = JSON.parse(event.target?.result) as SessionTrace;
				this.setState((prevState) => ({
					traces: [...prevState.traces, trace],
				}));
			} catch (error) {
				console.info("Error converting object to string", error);
			}
		};
	}

	async createSession(payload: PayloadBroadcastSubTrace) {
		const trace = {
			...payload.data,
			sessionId: payload.sessionId,
			logs: [],
			cc: new CC(),
		} as const satisfies SessionTrace;
		await this.connect(trace);
		this.setState((prevState) => ({
			traces: [...prevState.traces, trace],
		}));
	}

	closeSession(payload: PayloadBroadcastSubTrace) {
		this.setState((prevState) => ({
			traces: prevState.traces.map(async (trace) => {
				if (trace.sessionId === payload.sessionId) {
					await this.disconnect(trace);
					return {
						...trace,
						logs: [...trace.logs, { type: "rtrace", data: payload.msg }],
						closed: true,
					} as const satisfies SessionTrace;
				}
				return trace;
			}),
		}));
	}

	traceGlient(userId: UserId) {
		const cmd = {
			action: "traceGlient",
			username: userId,
		} as const satisfies CmdTraceGlient;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					loading: false,
					error: undefined,
					traces: msg.payload.data.map((trace) => ({
						...trace,
						logs: [],
						cc: new CC(),
					})),
				}, () => {
					this.state.traces.forEach(async (trace) => {
						await this.connect(trace);
					});
				});
			} else {
				this.setState({
					loading: false,
					error: msg?.payload.data ? JSON.stringify(msg.payload.data) : error?.message ?? "Unknown error",
					traces: [],
				});
			}
		});
	}

	handleTraceClick(sessionId: SessionIdOrGeneral) {
		if (this.state.selectedTraceSessionId !== sessionId) {
			this.setState({
				selectedTraceSessionId: sessionId,
			});
		}
	}

	async connect(trace: SessionTrace) {
		if (trace.cc?.disconnecting) {
			trace.cc.once("disconnected", async () => {
				await this.doConnect(trace);
			});
		} else {
			await this.doConnect(trace);
		}
	}

	async doConnect(trace: SessionTrace) {
		if (trace.cc && !trace.cc.connecting) {
			trace.cc.setLoginPayload({
				sessionId: trace.sessionId,
			});
			trace.cc.on("message-rx", this.handleCCMessage.bind(this, trace.sessionId));
			await trace.cc.connect();
		}
	}

	async disconnect(trace: SessionTrace, reconnect = false) {
		if (trace.cc?.ready) {
			if (!trace.cc.disconnecting) {
				if (reconnect) { // Workaround for tab unmount/mount problem
					trace.cc.once("disconnected", async () => {
						await this.connect(trace);
					});
				}
				trace.cc.off("message-rx", this.handleCCMessage.bind(this, trace.sessionId));
				await trace.cc.disconnect();
			}
		}
	}

	disconnectAll() {
		this.state.traces.forEach(async (trace) => {
			await this.disconnect(trace);
		});
	}

	handleCCMessage(sessionId: SessionId, msg: CcMessageRxKnown) {
		if (msg.payload.data && msg.payload.action === "jsonTrace" && msg.payload.module !== "shell") { // TODO: TerminalType
			const log = {
				type: msg.payload.action,
				data: msg.payload.data,
				ts: msg.payload.ts ?? new Date().toISOString(),
				dir: UserTrace.getDir(msg.payload),
			} as const satisfies Log;
			this.storeTraceToSession(sessionId, log);
		}
	}

	static getDir(payload: CcPayloadRxKnown) {
		if (payload.dir) {
			return payload.dir as Direction; // TODO
		}
		if (payload.data?.action) {
			return Constants.Direction.TX;
		}
		if (payload.data?.info) {
			return Constants.Direction.RX;
		}
		return undefined;
	}

	override render() {
		const { t, userId } = this.props;
		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const selectedTrace = (this.state.selectedTraceSessionId === SessionIdGeneral)
			? this.state.generalTrace
			: this.state.traces.find((trace) => (trace.sessionId === this.state.selectedTraceSessionId));

		return (
			<div style={{ display: "grid", gridTemplateColumns: "360px auto", columnGap: "16px" }}>
				<Paper style={{ overflowY: "auto", height: "calc(100vh - 156px)" }}>
					<List>
						<ListItemButton
							selected={this.state.selectedTraceSessionId === SessionIdGeneral}
							onClick={this.handleTraceClick.bind(this, SessionIdGeneral)}
						>
							<ListItemText primary={t("users.trace.general")} />
						</ListItemButton>
						{this.state.traces.map((trace, index) => (
							<Fragment key={trace.sessionId}>
								{(index > 0) && <Divider />}
								<ListItemButton
									selected={this.state.selectedTraceSessionId === trace.sessionId}
									onClick={this.handleTraceClick.bind(this, trace.sessionId)}
									style={trace.closed ? { backgroundColor: red[200] } : {}}
								>
									<ListItemText
										primary={trace.sessionId}
										secondary={trace.connected ?? trace.created}
										slotProps={{
											primary: {
												noWrap: true,
											},
											secondary: {
												noWrap: true,
											},
										}}
									/>
								</ListItemButton>
							</Fragment>
						))}
					</List>
				</Paper>
				<Paper>
					{selectedTrace &&
						<Terminal
							cc={selectedTrace.cc}
							module={TerminalType.CCC}
							readOnly={true}
							userId={userId}
							traces={[this.state.generalTrace, ...this.state.traces]}
							terminalEntries={selectedTrace.logs}
							sessionId={selectedTrace.sessionId}
							onLoadFile={this.handleLoadFile}
							style={{ borderRadius: "4px" }}
						/>
					}
				</Paper>
			</div>
		);
	}

}

export default withTranslation()(UserTrace);
