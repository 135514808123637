import { Component, createRef } from "react";
import { Navigate } from "react-router";
import { withTranslation } from "react-i18next";
import { Tab, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import UserGeneralInfo from "./UserGeneralInfo";
import UserGateways from "./UserGateways";
import UserOAuth2Tokens from "./user-oauth2-tokens";
import UserSockets from "./UserSockets";
import UserTrace from "./user-trace";
import UserTokens from "./UserTokens";
import B2CTokens from "./B2CTokens";
import UserLogs from "./UserLogs";
// services
import Gupport from "../../services/gupport";
import User from "../../services/user";
import { Storage, StorageKeys } from "../../services/storage";
// types
import type { WithTranslation } from "react-i18next";
import type { ReactRouterProps } from "../../types/misc";
import type { UserId, UserName } from "../../types/user";

type Props = Readonly<WithTranslation & ReactRouterProps & {
	name: UserName;
	userId: UserId;
}>;

type State = {
	selectedTab: any;
};

const TAB_NAMES = {
	GENERAL: "general",
	GATEWAYS: "gateways",
	OAUTH2TOKENS: "oauth2tokens",
	SOCKETS: "sockets",
	TRACE: "trace",
	TOKENS: "tokens",
	B2CTOKENS: "b2ctokens",
	LOGS: "logs",
} as const;

class UserTabs extends Component<Props, State> {

	#hasTraceGlient = Boolean(Gupport.traceGlient);
	#hasGetTokensAlb = Boolean(Gupport.getTokens);
	#hasGetLogs = Boolean(Gupport.getUserLogs);

	#references = {
		general: createRef(),
		gateways: null,
		oauth2tokens: null,
		sockets: createRef(),
		trace: createRef(),
		tokens: createRef(),
		b2cTokens: createRef(),
		logs: createRef(),
	};

	#timeout: number | null = null;

	constructor(props: Props) {
		super(props);

		let tab;
		if (this.props.params.tab) {
			Storage.set(StorageKeys.usersTab, this.props.params.tab);
			tab = this.props.params.tab;
		} else if (Storage.get(StorageKeys.usersTab)) {
			tab = Storage.get(StorageKeys.usersTab);
		} else {
			tab = TAB_NAMES.GENERAL;
		}

		this.state = {
			// ready: Gupport.ready,
			selectedTab: tab,
		};

		this.refresh = this.refresh.bind(this);
		this.handleTabChanged = this.handleTabChanged.bind(this);
	}

	override componentDidMount() {
		this.refresh();
	}

	override componentDidUpdate(prevProps) {
		if (this.#timeout) {
			window.clearTimeout(this.#timeout);
		}
		if (this.props.params.tab && this.props.params.tab !== prevProps.params.tab) {
			const tab = this.props.params.tab;
			Storage.set(StorageKeys.usersTab, tab);
			this.setState({
				selectedTab: tab,
			}, () => {
				this.refresh();
			});
		}
	}

	override componentWillUnmount() {
		if (this.#timeout) {
			window.clearTimeout(this.#timeout);
		}
	}

	refresh() {
		this.#timeout = window.setTimeout(() => {
			this.#references[this.state.selectedTab]?.current?.refresh?.();
			this.refresh();
		}, 30_000);
	}

	handleTabChanged(event, value) {
		if (this.#timeout) {
			window.clearTimeout(this.#timeout);
		}
		void this.props.navigate(`/users/${this.props.userId}/${value}`);
	}

	override render() {
		const { t, params, userId } = this.props;

		if (params.userId === undefined || params.tab === undefined) {
			if ((!this.#hasGetTokensAlb && this.state.selectedTab === "tokens") || (!this.#hasGetLogs && this.state.selectedTab === "logs")) {
				return <Navigate to={`/users/${userId}/general`} replace={true} />;
			} else {
				return <Navigate to={`/users/${userId}/${this.state.selectedTab}`} replace={true} />;
			}
		}

		return (
			<TabContext value={this.state.selectedTab}>
				<Paper style={{ marginBottom: "12px" }}>
					<TabList onChange={this.handleTabChanged}>
						<Tab label={t("users.info")} value={TAB_NAMES.GENERAL} />
						<Tab label={t("users.gateways")} value={TAB_NAMES.GATEWAYS} />
						<Tab label={t("users.oauth2tokens.title")} value={TAB_NAMES.OAUTH2TOKENS} />
						<Tab label={t("users.sockets")} value={TAB_NAMES.SOCKETS} />
						{this.#hasTraceGlient && <Tab label={t("users.trace.title")} value={TAB_NAMES.TRACE} />}
						{this.#hasGetTokensAlb && <Tab label={t("users.pushTokens")} value={TAB_NAMES.TOKENS} />}
						{User.hasLevel("user_b2c_read") && <Tab label={t("users.b2cTokens.title")} value={TAB_NAMES.B2CTOKENS} />}
						{this.#hasGetLogs && <Tab label={t("users.logs")} value={TAB_NAMES.LOGS} />}
					</TabList>
				</Paper>
				<TabPanel value={TAB_NAMES.GENERAL}><UserGeneralInfo ref={this.#references.general} userId={userId} /></TabPanel>
				<TabPanel value={TAB_NAMES.GATEWAYS}><UserGateways userId={userId} name={this.props.name} /></TabPanel>
				<TabPanel value={TAB_NAMES.OAUTH2TOKENS}><UserOAuth2Tokens userId={userId} /></TabPanel>
				<TabPanel value={TAB_NAMES.SOCKETS}><UserSockets ref={this.#references.sockets} userId={userId} /></TabPanel>
				{this.#hasTraceGlient && <TabPanel value={TAB_NAMES.TRACE}><UserTrace ref={this.#references.trace} userId={userId} /></TabPanel>}
				{this.#hasGetTokensAlb && <TabPanel value={TAB_NAMES.TOKENS}><UserTokens ref={this.#references.tokens} userId={userId} /></TabPanel>}
				{User.hasLevel("user_b2c_read") && <TabPanel value={TAB_NAMES.B2CTOKENS}><B2CTokens ref={this.#references.b2cTokens} userId={userId} /></TabPanel>}
				{this.#hasGetLogs && <TabPanel value={TAB_NAMES.LOGS}><UserLogs ref={this.#references.logs} userId={userId} /></TabPanel>}
			</TabContext>
		);
	}

}

export default withTranslation()(withNavigateAndParams(UserTabs));
