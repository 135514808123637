import { useTranslation } from "react-i18next";
// cmp
import CenterCircularProgress from "../cmp/CenterCircularProgress";
import UserTabs from "../cmp/user/UserTabs";
// hooks
import useSelectedUser from "../hooks/useSelectedUser";
// contexts
import { useUserData } from "../context/UserDataContext";

const UsersPage = () => {
	const { t } = useTranslation();

	const { ready } = useUserData();
	const { loading, selectedUser, isError } = useSelectedUser();

	if (!ready || loading) {
		return <CenterCircularProgress />;
	}
	if (!selectedUser) {
		return null;
	}
	if (isError) {
		return <div>{t("users.errorMsg")}</div>;
	}

	return <UserTabs userId={selectedUser.username} name={selectedUser.name} />;
};

export default UsersPage;
