import { useState, useEffect, useMemo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
// cmp
import Na from "../na";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { GridRowId, GridColDef } from "@mui/x-data-grid";
import type { CmdDeactivateGateway, CmdDisinviteUser, CmdGetGateways } from "../../types/gupport";
import type { UserId } from "../../types/user";
import type { Gateway } from "../../types/gateway";

type Props = Readonly<{
	userId: UserId;
	name: string;
}>;

const UserGateways = ({ userId, name }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<any>(null);
	const [gateways, setGateways] = useState<Array<Gateway>>([]);
	const [selectedGateway, setSelectedGateway] = useState<Gateway | undefined>(undefined);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
	const [openDisinviteDialog, setOpenDisinviteDialog] = useState(false);
	const [dialogActionText, setDialogActionText] = useState("");

	const fetchGatewayUsers = (userId: UserId) => {
		const cmd = {
			action: "getGateways",
			username: userId,
		} as const satisfies CmdGetGateways;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				const gateways = msg.payload.data as Array<Gateway>;
				gateways.sort((gatewayA/*, gatewayB*/) => ((gatewayA.level === "owner") ? 0 : 1));
				setError(null);
				setGateways(gateways);
			} else {
				setError(error?.message ?? msg.payload.data);
				setGateways([]);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchGatewayUsers(userId);
	}, []);

	const handleClose = () => {
		setSelectedGateway(undefined);
		setOpenDialog(false);
		setOpenDeactivateDialog(false);
		setOpenDisinviteDialog(false);
	};

	const disinviteUser = () => {
		if (selectedGateway) {
			const cmd = {
				action: "disinviteUser",
				gatewayId: selectedGateway.id,
				username: userId,
			} as const satisfies CmdDisinviteUser;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					fetchGatewayUsers(userId);
				} else {
					setOpenDialog(true);
					setDialogActionText(t("users.left"));
				}
				setOpenDisinviteDialog(false);
			});
		}
	};

	const deactivateGateway = () => {
		if (selectedGateway) {
			const cmd = {
				action: "deactivateGateway",
				gatewayId: selectedGateway.id,
				username: userId,
			} as const satisfies CmdDeactivateGateway;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					fetchGatewayUsers(userId);
				} else {
					setOpenDialog(true);
					setDialogActionText(t("users.deactivated"));
				}
				setOpenDeactivateDialog(false);
			});
		}
	};

	const isDisInviteUserPresent = Boolean(Gupport.disinviteUser);
	const isDeactivateUserPresent = Boolean(Gupport.deactivateGateway);

	const showDisinviteDialog = useCallback((id: GridRowId) => (
		() => {
			setSelectedGateway(gateways.find((gateway) => (gateway.id === id)));
			setOpenDisinviteDialog(true);
		}
	), [gateways]);

	const showDeactivateDialog = useCallback((id: GridRowId) => (
		() => {
			setSelectedGateway(gateways.find((gateway) => (gateway.id === id)));
			setOpenDeactivateDialog(true);
		}
	), [gateways]);

	const columns = useMemo(() => ([
		{
			field: "id",
			headerName: t("users.id"),
			flex: 2,
			renderCell: (params) => (
				<a
					href={`gateway_desc.html#/gateways/${encodeURIComponent(params.value)}/general`}
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: theme.palette.primary.main, textDecoration: "none" }}
				>
					{params.value}
				</a>
			),
		},
		{
			field: "code",
			headerName: t("users.gCode"),
			flex: 1,
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "name",
			headerName: t("users.gName"),
			flex: 3,
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "level",
			headerName: t("users.level"),
			flex: 1,
			type: "singleSelect",
			valueOptions: [Constants.Gateway.Level.Owner, Constants.Gateway.Level.User],
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "channel",
			headerName: t("users.channel"),
			flex: 1,
			type: "singleSelect",
			valueOptions: Object.values(Constants.Channel),
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "socket",
			headerName: t("users.socket"),
			flex: 1,
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "actions",
			headerName: "Action",
			type: "actions",
			flex: 1,
			getActions: (params) => (
				(params.row.level === "user" && isDisInviteUserPresent) ? [
					<GridActionsCellItem
						key={params.id}
						label={t("users.disinviteDialogTitle")}
						icon={<Svg src="actions/removeCircleOutlined.svg" color={theme.palette.danger.main} />}
						onClick={showDisinviteDialog(params.id)}
					/>,
				] : (params.row.level === "owner" && isDeactivateUserPresent) ? [
					<GridActionsCellItem
						key={params.id}
						label={t("users.deactivateDialogTitle")}
						icon={<icons.BlockIcon htmlColor={theme.palette.danger.main} />}
						onClick={showDeactivateDialog(params.id)}
					/>,
				] : []
			),
		},
	] as const satisfies ReadonlyArray<GridColDef<Gateway>>), [t, theme.palette.primary.main, theme.palette.danger.main, isDeactivateUserPresent, isDisInviteUserPresent, showDeactivateDialog, showDisinviteDialog]);

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<>
			<Paper>
				<DataGrid
					loading={loading}
					columns={columns}
					rows={gateways}
				/>
			</Paper>
			<Dialog
				fullWidth={true}
				open={openDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("users.dialog.title")}</DialogTitle>
				<DialogContent>
					{t("users.popMessage", { dialogActionText: dialogActionText })}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={openDeactivateDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("users.deactivateDialogTitle")}</DialogTitle>
				<DialogContent>
					<Trans
						i18nKey="users.deactivateDialogMsg"
						values={{ gateway: selectedGateway ? selectedGateway.name ?? selectedGateway.id : null }}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={deactivateGateway}>{t("users.deactivate")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={openDisinviteDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("users.disinviteDialogTitle")}</DialogTitle>
				<DialogContent>
					<Trans
						i18nKey="users.disinviteDialogMsg"
						values={{ name: name, userId: userId }}
					/>
					<Trans
						i18nKey="users.msgFrom"
						values={{ from: selectedGateway ? selectedGateway.name ?? selectedGateway.id : null }}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={disinviteUser}>{t("users.disinvite")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UserGateways;
